export const ACCOUNT_ADDRESS_SET = 'ACCOUNT_ADDRESS_SET';
export const ACCOUNT_DETAILS_SET = 'ACCOUNT_DETAILS_SET';

export const SELECT_ACCOUNT_DIALOG_SHOW = 'SELECT_ACCOUNT_DIALOG_SHOW';
export const SELECT_ACCOUNT_DIALOG_HIDE = 'SELECT_ACCOUNT_DIALOG_HIDE';

export const DELEGATIONS_FETCH_IN_PROGRESS = 'DELEGATIONS_FETCH_IN_PROGRESS';
export const DELEGATIONS_FETCH_SUCCESS = 'DELEGATIONS_FETCH_SUCCESS';
export const DELEGATIONS_FETCH_ERROR = 'DELEGATIONS_FETCH_ERROR';

export const BALANCE_FETCH_IN_PROGRESS = 'BALANCE_FETCH_IN_PROGRESS';
export const BALANCE_FETCH_SUCCESS = 'BALANCE_FETCH_SUCCESS';
export const BALANCE_FETCH_ERROR = 'BALANCE_FETCH_ERROR';

export const VESTING_BALANCE_FETCH_IN_PROGRESS = 'VESTING_BALANCE_FETCH_IN_PROGRESS';
export const VESTING_BALANCE_FETCH_SUCCESS = 'VESTING_BALANCE_FETCH_SUCCESS';
export const VESTING_BALANCE_FETCH_ERROR = 'VESTING_BALANCE_FETCH_ERROR';

export const UN_BONDING_DELEGATIONS_FETCH_IN_PROGRESS = 'UN_BONDING_DELEGATIONS_FETCH_IN_PROGRESS';
export const UN_BONDING_DELEGATIONS_FETCH_SUCCESS = 'UN_BONDING_DELEGATIONS_FETCH_SUCCESS';
export const UN_BONDING_DELEGATIONS_FETCH_ERROR = 'UN_BONDING_DELEGATIONS_FETCH_ERROR';

export const STAKE_ACCOUNT_ADDRESS_SET = 'STAKE_ACCOUNT_ADDRESS_SET';

export const REWARDS_FETCH_IN_PROGRESS = 'REWARDS_FETCH_IN_PROGRESS';
export const REWARDS_FETCH_SUCCESS = 'REWARDS_FETCH_SUCCESS';
export const REWARDS_FETCH_ERROR = 'REWARDS_FETCH_ERROR';

export const REVEALED_PUB_KEY_FETCH_IN_PROGRESS = 'REVEALED_PUB_KEY_FETCH_IN_PROGRESS';
export const REVEALED_PUB_KEY_FETCH_SUCCESS = 'REVEALED_PUB_KEY_FETCH_SUCCESS';
export const REVEALED_PUB_KEY_FETCH_ERROR = 'REVEALED_PUB_KEY_FETCH_ERROR';

export const DISCONNECT_SET = 'DISCONNECT_SET';

export const FETCH_SHIELDED_BALANCE_IN_PROGRESS = 'FETCH_SHIELDED_BALANCE_IN_PROGRESS';
export const FETCH_SHIELDED_BALANCE_SUCCESS = 'FETCH_SHIELDED_BALANCE_SUCCESS';
export const FETCH_SHIELDED_BALANCE_ERROR = 'FETCH_SHIELDED_BALANCE_ERROR';
